import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchIntakeQuestions,
  updateCaseIntakeForm,
  generatePublicLink,
  fetchPublicIntakeForm,
  submitPublicIntakeForm,
} from "../services/api";

const IntakeForm = ({ caseId, existingData, onSave, isPublic = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState(existingData || {});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [publicLink, setPublicLink] = useState(null);

  useEffect(() => {
    const loadForm = async () => {
      try {
        console.log(
          "Loading form. isPublic:",
          isPublic,
          "id:",
          id,
          "caseId:",
          caseId,
        );
        let fetchedQuestions;
        if (isPublic) {
          console.log("Fetching public intake form");
          const data = await fetchPublicIntakeForm(id);
          console.log("Received data:", data);
          if (!data || !data.questions || !data.case) {
            throw new Error("Invalid data received from server");
          }
          fetchedQuestions = data.questions;
          setResponses(data.case.intake_form || {});
        } else {
          console.log("Fetching intake questions");
          fetchedQuestions = await fetchIntakeQuestions();
        }
        console.log("Fetched questions:", fetchedQuestions);
        if (!Array.isArray(fetchedQuestions) || fetchedQuestions.length === 0) {
          throw new Error("No questions received from server");
        }
        fetchedQuestions.sort((a, b) => a.order_idx - b.order_idx);
        setQuestions(fetchedQuestions);
        setLoading(false);
      } catch (err) {
        console.error("Error loading intake form:", err);
        setError(`Failed to load the intake form: ${err.message}`);
        setLoading(false);
      }
    };
    loadForm();
  }, [isPublic, id, caseId]);

  const handleInputChange = (questionId, value) => {
    setResponses((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isPublic) {
        await submitPublicIntakeForm(id, responses);
        navigate("/thank-you");
      } else {
        await updateCaseIntakeForm(caseId, responses);
        onSave(responses);
      }
    } catch (err) {
      console.error("Error submitting intake form:", err);
      setError("Failed to submit the form. Please try again later.");
    }
  };

  const handleGeneratePublicLink = async () => {
    try {
      const link = await generatePublicLink(caseId);
      setPublicLink(link);
    } catch (err) {
      console.error("Error generating public link:", err);
      setError("Failed to generate public link. Please try again later.");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Link copied to clipboard");
        // You could add a toast notification here
      },
      (err) => {
        console.error("Could not copy text: ", err);
      },
    );
  };

  const getInputClassName = (type) => {
    const baseClasses =
      "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50";
    return type === "small_text" ? baseClasses : `${baseClasses} py-3 text-lg`;
  };

  const renderInputField = (question) => {
    const inputClassName = getInputClassName(question.type);
    switch (question.type) {
      case "text":
      case "small_text":
      case "email":
      case "tel":
      case "number":
        return (
          <input
            type={question.type === "small_text" ? "text" : question.type}
            id={`question-${question.id}`}
            value={responses[question.id] || ""}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className={inputClassName}
          />
        );
      case "date":
        return (
          <input
            type="date"
            id={`question-${question.id}`}
            value={responses[question.id] || ""}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className={inputClassName}
          />
        );
      case "textarea":
        return (
          <textarea
            id={`question-${question.id}`}
            value={responses[question.id] || ""}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className={`${inputClassName} h-32`}
            rows="3"
          />
        );
      default:
        return (
          <input
            type="text"
            id={`question-${question.id}`}
            value={responses[question.id] || ""}
            onChange={(e) => handleInputChange(question.id, e.target.value)}
            className={inputClassName}
          />
        );
    }
  };

  if (loading)
    return <div className="text-center py-4">Loading intake form...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (questions.length === 0)
    return (
      <div className="text-center py-4">
        No questions available for this form.
      </div>
    );

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-5">
      <h2 className="text-2xl font-bold mb-4">Intake Form</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {questions.map((question) => (
          <div key={question.id} className="mb-4">
            <label
              htmlFor={`question-${question.id}`}
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {question.text}
            </label>
            {renderInputField(question)}
          </div>
        ))}
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {isPublic ? "Submit Form" : "Save Intake Form"}
        </button>
      </form>
      {!isPublic && (
        <div className="mt-4">
          <button
            onClick={handleGeneratePublicLink}
            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Generate Public Link
          </button>
          {publicLink && (
            <div className="mt-4 flex items-center space-x-2">
              <input
                value={publicLink}
                readOnly
                className="flex-grow mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <button
                onClick={() => copyToClipboard(publicLink)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Copy
              </button>
              <a
                href={publicLink}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Open
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IntakeForm;
