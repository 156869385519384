import axios from "axios";
import { supabase } from "../supabaseClient";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5059/api";

// Create an axios instance
const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the auth token in every request
api.interceptors.request.use(
  async (config) => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const addCase = async (caseData) => {
  try {
    const response = await api.post("/cases", caseData);
    return response.data;
  } catch (error) {
    console.error("Error adding case:", error);
    throw new Error("Failed to add case");
  }
};

export const fetchCases = async () => {
  try {
    const response = await api.get("/cases");
    return response.data;
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw new Error("Failed to fetch cases");
  }
};

export const fetchCaseDetails = async (id) => {
  try {
    const response = await api.get(`/cases/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const notFoundError = new Error("Case not found");
      notFoundError.response = { status: 404 };
      throw notFoundError;
    }

    console.error("Error fetching case details:", error);
    throw new Error("Failed to fetch case details");
  }
};

export const uploadFiles = async (files, case_id) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("case_id", case_id);

  try {
    const response = await api.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw new Error(error.response.data.error);
  }
};

export const addAnalysis = async (analysisData) => {
  try {
    const response = await api.post("/analysis", analysisData);
    return response.data;
  } catch (error) {
    console.error("Error adding analysis:", error);
    throw new Error(error.response.data.error);
  }
};

export const fetchAnalysis = async (id) => {
  try {
    const response = await api.get(`/analysis/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const notFoundError = new Error("Analysis not found");
      notFoundError.response = { status: 404 };
      throw notFoundError;
    }
    console.error("Error fetching analysis:", error);
    throw error;
  }
};

export const deleteAnalysis = async (id) => {
  try {
    const response = await api.delete(`/analysis/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting analysis:", error);
    throw error;
  }
};

export const fetchAnalysisTypes = async () => {
  try {
    const response = await api.get("/analysis-types");
    return response.data;
  } catch (error) {
    console.error("Error fetching analysis types:", error);
    throw new Error("Failed to fetch analysis types");
  }
};

export const fetchPdfUrl = async (fileId) => {
  try {
    const response = await api.get(`file/${fileId}`, {
      responseType: "blob",
    });
    console.log("resp", response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    console.log("blob", blob);
    const url = URL.createObjectURL(blob);
    console.log("url", url);
    return url;
  } catch (error) {
    console.error("Failed to fetch PDF:", error);
    throw new Error("Failed to fetch PDF");
  }
};

export const downloadFile = async (fileId) => {
  try {
    const response = await api.get(`file/${fileId}`, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // Get the filename from the Content-Disposition header if available
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/"/g, "")
      : `file-${fileId}.pdf`;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
    throw new Error("Failed to download file");
  }
};

export const deleteFile = async (fileId) => {
  try {
    const response = await api.delete(`/file/${fileId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting file:", error);
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.error
    ) {
      if (error.response.data.error.includes("foreign key constraint")) {
        throw new Error(
          "Couldn't delete file because evidence reports exist based on it. You need to delete those first.",
        );
      } else if (error.response.data.error.includes("RLS")) {
        throw new Error(
          "Couldn't delete file because you don't have permission.",
        );
      }
    }
    throw new Error("Failed to delete file");
  }
};

export const fetchIntakeQuestions = async () => {
  try {
    const { data, error } = await supabase
      .from("intake_questions")
      .select("*")
      .order("id", { ascending: true });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching intake questions:", error);
    throw new Error("Failed to fetch intake questions");
  }
};

export const updateCaseIntakeForm = async (caseId, formData) => {
  try {
    const { data, error } = await supabase
      .from("cases")
      .update({ intake_form: formData })
      .eq("id", caseId);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating case intake form:", error);
    throw new Error("Failed to update case intake form");
  }
};

export const generatePublicLink = async (caseId) => {
  try {
    const response = await axios.post(
      `${API_URL}/cases/${caseId}/public-link`,
      {},
      {
        headers: {
          Authorization: `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      },
    );

    if (response.data && response.data.publicUrl) {
      return response.data.publicUrl;
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error generating public link:", error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to generate public link");
  }
};

export const fetchPublicIntakeForm = async (id) => {
  try {
    console.log("Fetching public intake form for id:", id);
    const response = await axios.get(`${API_URL}/public-intake/${id}`);
    console.log("Received response:", response.data);

    if (response.data && response.data.case && response.data.questions) {
      return response.data;
    } else {
      console.error("Unexpected response structure:", response.data);
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching public intake form:", error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to fetch public intake form");
  }
};

export const submitPublicIntakeForm = async (id, formData) => {
  try {
    console.log("Submitting public intake form for id:", id);
    const response = await axios.post(
      `${API_URL}/public-intake/${id}`,
      formData,
    );
    console.log("Received response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error submitting public intake form:", error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to submit public intake form");
  }
};

export const updateCase = async (id, caseData) => {
  try {
    const { data, error } = await supabase
      .from("cases")
      .update(caseData)
      .eq("id", id)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        throw new Error("You don't have permission to edit this case.");
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error updating case:", error);
    throw error; // Throw the error to be handled by the component
  }
};
